var firebaseConfig = {
    apiKey: "AIzaSyBzn3AL3w0BqFxoD2FS4GX7PtjWZ6m7mpc",
    authDomain: "shaketoplay.firebaseapp.com",
    databaseURL: "https://shaketoplay.firebaseio.com",
    projectId: "shaketoplay",
    storageBucket: "shaketoplay.appspot.com",
    messagingSenderId: "614655698730",
    appId: "1:614655698730:web:a50557eb3a19209bd06053",
    measurementId: "G-C1RBSNE1JR"
};

export default firebaseConfig
// Initialize Firebase


// export default storage
