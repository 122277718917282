import React from 'react';
import './Notification.scss';

const Notification = (props) => {
    return (
        <div className="notification">
            {props.text ? props.text : 'Add text prop'}
        </div>
    );
}

export default Notification