import React, { useState, useEffect, useCallback } from 'react';
import './Timer.scss';

const Timer = (props) => {
    const [progress, setProgress] = useState(0.05);
    const [timeLeft, setTimeLeft] = useState('');

    const calculateTimeLeft = useCallback (() => {
        const difference = +new Date(props.date) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
            setProgress((difference / 1000) / props.time);
        }

        return timeLeft;
    }, [props.date, props.time]);

    // Clock
    useEffect(() => {
        if (props.date) {
            setTimeout(() => {
                setTimeLeft(calculateTimeLeft());
            }, 1000); 
        } 
    }, [timeLeft, setTimeLeft, calculateTimeLeft, props.date]);

    // Set Clock
    useEffect(() => {
        setTimeLeft(calculateTimeLeft())
    }, [props.date, calculateTimeLeft])

    // If timer isn't running, show the total time
    useEffect(() => {
        setTimeLeft({
            hours: Math.floor((props.time / (60 * 60)) % 24),
            minutes: Math.floor((props.time / 60) % 60),
            seconds: Math.floor(props.time % 60),
        }) 
    }, [props.time]);

    const timerComponents = [];
    Object.keys(timeLeft).map(interval => {
        timerComponents.push(
            <span className={progress <= .20 ? 'red' : null} key={interval}>{minTwoDigits(timeLeft[interval])}</span>
        );
        return null;
    });

    function minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    const progressStep = () => {
        if (progress <= .25) return 'step-1';
        else if (progress > .25 && progress <= .6) return 'step-2';
        else return 'step-3';
    }

    return (
        <div className="inner">
            <div style={{height: progress * 100 + '%'}} className={`progress ${progressStep()}`}></div>
            {timerComponents.filter(number => number !== 0).length ? timerComponents : <span>Time's up!</span>}
        </div>
    );

}

export default Timer