import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore'; 
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database'
import firebaseConfig from "../../lib/firebaseConfig"
import { useHistory } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { useGlobal, setGlobal } from 'reactn';

import './EveryGardenView.scss';

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const EveryGardenView = (props) => {
    const gardensRef = firebase.database().ref(`/topics/`)
    const [gardensArray, setGardensArray] = useState([])

    const [showingGardens, setShowingGardens] = useState([])
    const [totalGardensNumber, setTotalGardensNumber] = useState(0)
    const [showingGardensNumber, setShowingGardensNumber] = useState(0)

    const [counter, setCounter] = useState(0)
    const [searchString, setSearchString] = useState('')
    const [everyGardenSortBy] = useGlobal('everyGardenSortBy')
    const [sortBy, setSortBy] = useState('newest')

    let history = useHistory();

    useEffect(() => {
        setSortBy(everyGardenSortBy)
    }, [everyGardenSortBy])


    useEffect(() => {
        
        gardensRef.on('value', (snapshot) => {
            const newVal = snapshot.val()
            if (newVal) {
                let array = Object.keys(newVal).map(i => {
                    newVal[i]['id'] = i;
                    return newVal[i]
                })
                setGardensArray(array)
            }
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        getSortedGardens();
    }, [gardensArray, searchString, sortBy, props.showAddTopic]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSortBy = (type) => {
        setSortBy(type)
        setGlobal({
            ...global,
            everyGardenSortBy: type
        })
    }


    const renderDescriptionBox = () => {
        return (
            <div className={`description-box `}>
                <div className='page-body'>
                    <div className='page-title'>
                        Collection of Strange Gardens ({showingGardensNumber}/{totalGardensNumber})
                </div>

                </div>
                <div className='sortby'>
                    {/* <div className='sortby-text'>Sort by</div> */}
                    <div className='sortby-options'>
                        <span className='gray'> Sort by: </span>
                        <div className={`option ${sortBy === 'newest' ? 'active' : 'false'}`}
                            onClick={() => handleSortBy('newest')}>newest</div> <span className='gray'>/</span>
                        {/* <div className={`option ${sortBy === 'oldest' ? 'active' : 'false'}`}
                            onClick={() => handleSortBy('oldest')}>oldest</div> <span className='gray'>/</span> */}
                        <div className={`option ${sortBy === 'featured' ? 'active' : 'false'}`}
                            onClick={() => handleSortBy('featured')}> featured</div><span className='gray'>/</span>
                        <div className={`option ${sortBy === 'active' ? 'active' : 'false'}`}
                            onClick={() => handleSortBy('active')}>active</div>
                    </div>
                </div>
                <div className='actions'>


                    <div className='search-garden'>
                        <input type="text" placeholder={'Search garden'} onChange={(e) => setSearchString(e.target.value.toLowerCase())}></input>
                    </div>
                    <div className='button'>
                        <a href="../add"> Make new garden</a>
                    </div>
                </div>

            </div>
        )
    }


    const handleGoToGarden = (topic) => {
        history.push('/of/' + topic);
    }

    // const getRowsGardens = (array) => {

    //     const columns = getColumnsNr()
    //     let arrayOfColumns = []
    //     for (let i = 0; i < array.length / columns; i++) {
    //         const newArray = array.splice(i * columns, columns)
    //         arrayOfColumns = [...arrayOfColumns, newArray]
    //     }
    //     return arrayOfColumns
    // }

    const sortGardensBy = (type, gardens) => {
        switch (type) {
            case 'active':
                return gardens.sort((a, b) => (a.lastActiveAt < b.lastActiveAt) ? 1 : ((b.lastActiveAt < a.lastActiveAt) ? -1 : 0))
            case 'newest':
                return gardens.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))
            case 'oldest':
                return gardens.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
            case 'featured':
                return gardens.sort((a, b) => (a.imagesNr < b.imagesNr) ? 1 : ((b.imagesNr < a.imagesNr) ? -1 : 0))
            default:
                // newest is default
                return gardens.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))
        }
    }

    const getSortedGardens = () => {
        let temp_gardensArray = gardensArray;
        temp_gardensArray = gardensArray.filter(garden => {
            return garden.private !== true
        });
        // remove empty gardens or with few images
        temp_gardensArray = temp_gardensArray.filter(garden => {
            return garden.imagesNr > 5
        })
        // remove test gardens
        temp_gardensArray = temp_gardensArray.filter(garden => {
            return garden.id.includes('chess') ? true : !garden.id.includes('test')
        })

        // remove sonic gardens
        // temp_gardensArray = temp_gardensArray.filter(garden => {
        //     return !garden.sonic
        // })
        if (searchString.length > 0) {
            temp_gardensArray = gardensArray.filter(garden => {
                return garden.private !== true && garden.id.includes(searchString)
            });
            temp_gardensArray = Array.from(new Set(temp_gardensArray));
        }

        setTotalGardensNumber(gardensArray.length)
        setShowingGardensNumber(temp_gardensArray.length)

        // setShowingGardens(getRowsGardens(sortGardensBy(sortBy, temp_gardensArray)))
        setShowingGardens(sortGardensBy(sortBy, temp_gardensArray))
    }

    const renderGarden = (garden) => {
        return (
            <div
                key={garden.id}
                onClick={() => handleGoToGarden(garden.id)}
                className='garden-info'
                style={{ background: `${garden.background}` }}
            >
                <LazyLoad once offset={100}>
                    {garden.thumbnailURL && <img className='thumbnail' src={garden.thumbnailURL} alt='img' />}
                </LazyLoad>
                {/* {!garden.thumbnailURL && <div className='garden-title'>{garden.id}</div>} */}
                <div className='garden-title'>{garden.id}</div>
                <div className='enter-garden'>Enter this garden</div>
            </div>
        )
    }


    useEffect(
        () => {
            const id = setTimeout(() => {
                // if there's no search, keep walking. If there's search, stop walking
                searchString.length === 0 && setCounter(counter + 1);
            }, 2000);
            return () => {
                clearTimeout(id);
            };
        },
        [counter, searchString],
    );


    return (
        <div className={`EveryGardenView`}>
            {renderDescriptionBox()}
            {/* <NavBar /> */}
            <div className={`every-garden-wrapper big`}>
                <div className={`every-garden`}>

                    {showingGardens.map((garden, id) => {
                        return (
                            <div className="unique-garden" key={id}>
                                {renderGarden(garden)}
                            </div>
                        )
                    })}

                </div>
            </div>

        </div >
    )
}

export default EveryGardenView