import React from 'react';
import { useHistory } from "react-router-dom";
import FloatingObject from '../../components/FloatingObject/FloatingObject';

import './AboutView.scss'
import FooterAbout from '../FooterAbout/FooterAbout';

const description1 = `Strange Garden is an open 
collaborative collage generator, used as a fun and engaging activity to open video calls, as an icebreaker, or for brainstorming.`


const AboutView = () => {
    const history = useHistory();

    const handleCreateGarden = () => {
        history.push('/add')
    }
    return (
        <div className='AboutView'>
            {/* ------ Beginning of section ------ */}
            <div className='section one grid'>
                <div className='title'>
                    What is Strange Garden?
                </div>
                <div className='text'>
                    {description1}
                </div>
                <div className='actions'>
                    Follow us in
                    <a className='media-link' target="_blank" rel="noopener noreferrer" href={'https://twitter.com/strangestgarden'}>Twitter</a>
                    or 
                    <a className='media-link' target="_blank" rel="noopener noreferrer" href={'https://www.instagram.com/strangestgarden/'}>Instagram</a>
                </div>
                <FloatingObject imgSrc={'/object_images/1.png'} scale={1} position={[10, 10]} />
                <FloatingObject imgSrc={'/object_images/2.png'} scale={.7} position={[85, 35]} />
                <FloatingObject imgSrc={'/object_images/3.png'} scale={1} position={[10, 95]} />
                <FloatingObject imgSrc={'/object_images/5.png'} scale={1.3} position={[70, 10]} />
                <FloatingObject imgSrc={'/object_images/6.png'} scale={1.5} position={[45, 60]} />
                <FloatingObject imgSrc={'/object_images/7.png'} scale={1} position={[70, 75]} />
            </div>
            <div className='section three grid'>
                <div className='title'>
                    Bridge the physical and the digital
                </div>
                <div className='subtitle'>
                    Strange Garden brings people and its physical surrounding together in the digital world in a seamless way.
                </div>
                <div className='about-actions'>
                    <div className='button primary' onClick={() => handleCreateGarden()}>Make a garden</div>
                    <div className='button primary' onClick={() => history.push('/everygarden')}>See all Strange Gardens</div>
                </div>
                <FloatingObject imgSrc={'/object_images/8.png'} scale={.7} position={[60, 10]} />
                <FloatingObject imgSrc={'/object_images/9.png'} scale={1} position={[10, 75]} />
                <FloatingObject imgSrc={'/object_images/4.png'} scale={1} position={[85, 60]} />
            </div>

            {/* ------ Beginning of section ------ */}
            <div className='section one right grid'>
                <div className='title'>
                    How does Strange Garden work?
                </div>
                <FloatingObject imgSrc={'/object_images/1.png'} scale={1} position={[10, 10]} />
                <FloatingObject imgSrc={'/object_images/6.png'} scale={.7} position={[70, 70]} />
                <FloatingObject imgSrc={'/object_images/3.png'} scale={1} position={[10, 75]} />
            </div>
            <div className='section two grid'>
                <ul className='text'> 
                    <li>To try out working together, you can use our test collage <a className='link' href='https://strange.garden/of/favourite-objects-around-you'>Favourite objects around you</a>.
                    At the bottom right you will find a camera symbol. You can click on it to open your camera and take pictures. Or you can scan the QR code with your phone to take photos straight from your device. If you just click anywhere on the collage, you can write something.</li>
                    <li>To create a collage, go to <a className='link' href='https://strange.garden'>Strange Garden</a>, enter the desired title and define the settings. </li>
                </ul>
                <FloatingObject imgSrc={'/object_images/3.png'} scale={.7} position={[78, 8]} />
                <FloatingObject imgSrc={'/object_images/8.png'} scale={1} position={[10, 75]} />
                <FloatingObject imgSrc={'/object_images/2.png'} scale={1} position={[85, 60]} />
            </div>
            <div className='section three grid'>
                <div className='title'>
                    Further details when creating your garden
                </div>
                <ul className='subtitle'>
                    <li>Private means that the collage does not appear in the gallery. Anyone who knows the link can participate.</li>
                    <li>You can leave the random color set for the background color - or enter your own color code or create your background gradient <a className='link' href={'https://cssgradient.io/'}>here</a></li>
                    <li>The timer function will automatically "close" the collage after a certain time entered so no more images can be posted. You can also set the timer to restart as soon as something has been uploaded.</li>
                </ul>
                <FloatingObject imgSrc={'/object_images/4.png'} scale={.7} position={[60, 10]} />
                <FloatingObject imgSrc={'/object_images/10.png'} scale={1} position={[10, 75]} />
                <FloatingObject imgSrc={'/object_images/7.png'} scale={1} position={[85, 60]} />
            </div>

            {/* ------ Beginning of section ------ */}
            <div className='section one grid'>
                <div className='title'>
                    Ideas for using Strange Garden
                </div>
                <FloatingObject imgSrc={'/object_images/1.png'} scale={1} position={[10, 20]} />
                <FloatingObject imgSrc={'/object_images/5.png'} scale={.7} position={[80, 40]} />
                <FloatingObject imgSrc={'/object_images/6.png'} scale={1} position={[60, 15]} />
                <FloatingObject imgSrc={'/object_images/2.png'} scale={1} position={[25, 80]} />
            </div>

            <div className='section two grid'>
                <ul className='text'>
                    <li>As a warm-up at the time of arrival in a video conference: For example, everyone involved can briefly share the view from their window. This creates a joint collage with all the window views - and everyone knows a little more who they are dealing with.</li>
                    <li>As a 'collection point' for brainstorms, or trips: Collages could be created for a group on certain topics - and everyone can contribute if they find something they are looking for.</li>
                    <li>As a 'Show me something about yourself' task: e.g. 'Share something that excites you', 'Share something that makes you calm',' Share your favorite object around you'...</li>
                </ul>
                <FloatingObject imgSrc={'/object_images/10.png'} scale={.7} position={[70, 10]} />
                <FloatingObject imgSrc={'/object_images/3.png'} scale={1} position={[10, 45]} />
                <FloatingObject imgSrc={'/object_images/4.png'} scale={1} position={[55, 60]} />
            </div>

            <div className='section three grid'>
                <div className='title'>
                    How could we improve Strange Garden
                </div>
                <div className='subtitle'>
                    If you enjoy using Strange Garden write us an email to thestrangegarden@gmail.com and tell us about your experience.<br /><br />
                    Submit your ideas for improvements in our <a className='link' href={'https://trello.com/invite/b/rSr4LhKq/b5fb514683415a3e62f6653ed25b0be1/strange-garden'}>Public Trello Board</a>. We'll love to see what your preferences are to keep improving Strange Garden.
                </div>
                <FloatingObject imgSrc={'/object_images/2.png'} scale={.7} position={[50, 20]} />
                <FloatingObject imgSrc={'/object_images/1.png'} scale={1} position={[80, 75]} />
                <FloatingObject imgSrc={'/object_images/4.png'} scale={1} position={[95, 30]} />
            </div>
            {/* ------ Beginning of section ------ */}
            <div className='section one right grid'>
                <div className='title'>
                    What others say of Strange Garden
                </div>
                <FloatingObject imgSrc={'/object_images/4.png'} scale={1} position={[10, 10]} />

                <FloatingObject imgSrc={'/object_images/3.png'} scale={1} position={[10, 75]} />
                <FloatingObject imgSrc={'/object_images/7.png'} scale={1} position={[85, 60]} />
            </div>

            <div className='section two grid'>
                <ul className='text'>
                    <li>Read here how to use Strange Garden for education: <a className='link' href={`https://ebildungslabor.de/blog/kollaborativecollagen`}>By EbildungsLabor</a></li>
                    <li>Read and watch a video explaining how to use Strange Garden: <a className='link' href={`https://lernsachen.blog/2021/01/28/strange-garden-kollaborativ-geschichten-erzahlen/`}>By Thomas</a></li>
                </ul>
                <FloatingObject imgSrc={'/object_images/2.png'} scale={.7} position={[60, 10]} />
                <FloatingObject imgSrc={'/object_images/5.png'} scale={1} position={[10, 75]} />
                <FloatingObject imgSrc={'/object_images/9.png'} scale={1} position={[35, 60]} />
            </div>
            <FooterAbout />
        </div>
    )
}

export default AboutView
