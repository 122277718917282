import React from 'react';
import { Trash2 } from 'react-feather';
import './DeleteItemFromGarden.scss';

const DeleteItemFromGarden = ({item, handleDelete}) => {
    return (
        <div className='DeleteItemFromGarden' onClick={() => handleDelete(item)}>
            <Trash2 />
            </div>
    )
}

export default DeleteItemFromGarden;