import React, { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/firestore'; 
import 'firebase/auth';
import 'firebase/storage';
import firebaseConfig from "../../lib/firebaseConfig"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import CameraView from '../CameraView/CameraView'
import AddTopicView from '../AddTopicView/AddTopicView'
import Timer from '../../components/Timer/Timer'
import Notification from '../../components/Notification/Notification'

import './TopicView.scss';
import ScrencaptureWrapper from '../../components/Screencapture/ScrencaptureWrapper';

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage()

const TopicView = (props) => {
    const [seeOriginal, setSeeOriginal] = useState(true)
    const history = useHistory();
    const location = useLocation();
    const hasEditRights = window.location.href.includes('adminViewForLuukAndMarta')

    const [topicInfo, setTopicInfo] = useState('');
    const topicsRef = firebase.database().ref(`/topics/`);
    let { topicId } = useParams();
    const activeVersion = topicId;
    const [showCamera, setShowCamera] = useState(false);
    
    const retrieveTopicInfo = useCallback (() => {
        topicsRef.child(activeVersion.toLowerCase()).on("value",snapshot => {
            if (snapshot.exists()) {
                const obj = {
                    time: snapshot.val().time,
                    background: snapshot.val().background,
                    resetable: snapshot.val().resetable,
                    endTime: snapshot.val().endTime,
                    ended: snapshot.val().ended,
                    imagesNr: snapshot.val().imagesNr,
                    thumbnailURL: snapshot.val().thumbnailURL,
                    lastActiveAt: snapshot.val().lastActiveAt,
                    thumbnailURLCreatedAt: snapshot.val().thumbnailURLCreatedAt
                }
                setTopicInfo(obj);
            } else {
                setTopicInfo('new');
            }
        });
    }, [activeVersion, topicsRef]);

    useEffect(() => {
        // Wait 8sec for animation to finish and then remove notification state
        location.state && location.state.new && setTimeout(() => history.replace(), 8000);

        // Pull in firebase
        retrieveTopicInfo()
    }, [history, location.state]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleFirebaseBase64Upload = (base64Img) => {
        const name = `${activeVersion}_thumb.png`
        const uploadTask = storage.ref(`/images/${activeVersion.toLowerCase()}/${name}`).putString(base64Img, 'data_url', { contentType: 'image/jpg' });
        uploadTask.on('state_changed',
            (snapShot) => {
                console.log('storing---', snapShot)
            }, (err) => {
                console.log(err)
            }, () => {
                storage.ref(`images/${activeVersion.toLowerCase()}`).child(name).getDownloadURL()
                    .then(fireBaseUrl => {
                        handleSaveThumbnail(fireBaseUrl)
                    })
            })
    }

    const handleSaveThumbnail = (_thumbUrl) => {
        topicsRef.child(activeVersion.toLowerCase()).update({
            thumbnailURL: _thumbUrl,
            thumbnailURLCreatedAt: Date.now()
        });
    }
    
    const handleGoToGarden = () => {
        setSeeOriginal(false)
        if (topicInfo.time !== 0 ) {
            const date = new Date();
            const endTime = new Date( date.getTime() + 1000 * topicInfo.time );
            topicsRef.child(activeVersion.toLowerCase()).update({
                'endTime': endTime
            });
        }
    }

    const renderDescriptionCanvas = () => {
        return (
            <div>
                <div className={`page-description ${!seeOriginal || topicInfo.time === 0 || topicInfo.endTime ? 'hide' : 'see'}`}>
                    <div className='page-title'>
                        The Garden of {activeVersion.replace(/-/g, ' ')}
                    </div>
                    <div className='page-body'>
                        <p>Take a picture of something that connects to {activeVersion}, and contribute to the garden!</p>
                        { topicInfo.time !== 0 &&  <p><strong>Opening the garden will start the timer.</strong></p> }
                    </div>
                    <div className='button' onClick={() => handleGoToGarden()}>
                        { topicInfo.time === 0 ? `Start adding your ${activeVersion}` : `Open the garden!` }
                    </div>
                </div>
                { topicInfo.time !== 0 && 
                    <div className='timer'>
                        <Timer time={topicInfo.time} date={topicInfo.endTime} />
                    </div>
                }
                {location.state && location.state.new && <Notification text="Created! Link is copied to clipboard." />}
                {showCamera && <CameraView version={activeVersion} fromDelightsGarden={false} imagesNr={topicInfo.imagesNr} onCloseCamera={() => setShowCamera(false)}/>}
                <ScrencaptureWrapper 
                    gardenType={activeVersion.replace(/-/g, ' ')}
                    noCamera={props.noCamera ? true : false} 
                    version={'topic'} 
                    topic={props.topic} 
                    onShowCamera={() => handleShowCamera(activeVersion)} 
                    onSaveThumbnail={handleFirebaseBase64Upload}
                    topicInfo={topicInfo} 
                    editRights={hasEditRights}    
                    />
            </div>
        )
    }

    const handleShowCamera = (version) => {
        history.push(`/camera/${version}`)
    }
    
    return (
        <div className='TopicView'>
            {topicInfo.time !== undefined ? renderDescriptionCanvas() : topicInfo === 'new' ? <AddTopicView new={activeVersion} /> : null }
        </div>
    )
}

export default TopicView