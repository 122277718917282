import React, { useState } from 'react';
import './DeleteGarden.scss';

const DeleteGarden = ({ item, handleDeleteGarden }) => {
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const handleConfirmDelete = () => {
         handleDeleteGarden()
    }
    const renderConfirmationPopup = () => {
        return (
            <div className='confirmation-popup'>
                <div className='popup-content'>
                    Are you sure you want to delete this garden? This will be irreversible and will delete all content inside it.
                    <div className='actions'>
                        <div className='btn primary' onClick={() => handleConfirmDelete()}>Yes</div>
                        <div className='btn secondary' onClick={() => setShowConfirmationPopup(false)}>No</div>
                    </div>

                </div>
            </div>
        )
    }
    return (
        <div className='DeleteGarden'>
            <div className='delete-garden-btn' onClick={() => setShowConfirmationPopup(true)}>
                Delete Garden
        </div>
            {showConfirmationPopup && renderConfirmationPopup()}
        </div >
    )
}

export default DeleteGarden;