import React from 'react';
import Draggable from 'react-draggable';

import './FloatingObject.scss'


const FloatingObject = (props) => {
    return (
        <Draggable>
            <div className='FloatingObject'
                style={{ position: 'absolute', left: `${props.position[0]}%`, 
                top: `${props.position[1]}%`, transform: `scale(${props.scale})` }}>
                <img src={props.imgSrc} alt='img' draggable="false"/>
            </div>
        </Draggable>
    )
}

export default FloatingObject