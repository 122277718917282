import React from 'reactn';
import Popup from "reactjs-popup";

import ScreenCapture from "./Screencapture.js";
import './ScreencaptureWrapper.scss'
import CanvasView from "../../pages/CanvasView/CanvasView.js";

class ScrencaptureWrapper extends React.Component {
  state = {
    screenCapture: "",
    open: false,
    title: `Strange Garden of ${this.props.gardenType.toString()}`.toUpperCase(),
    takingScreenshot: false,
  };

  handleScreenCapture = screenCapture => {
    console.log('taking thumb', this.global.takeScreenshotThumbnail)
    this.global.takeScreenshotThumbnail ? this.handleSaveThumbnail(screenCapture)
      : this.setState(
        {
          screenCapture
        },
        () => {
          this.setState({ takingScreenshot: false })
          screenCapture && this.openModal()
        }
      );
  };

  handleSaveThumbnail = (screenCapture) => {
    this.setState({ takingScreenshot: false })
    window.innerWidth > 600 && this.props.onSaveThumbnail(screenCapture)
  }

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false, screenCapture: "" });
  };

  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSave = () => {
    console.log(this.state.title, this.state.screenCapture);
  };

  handleStartScreenScapture = () => {
    this.setState({ takingScreenshot: true })
  }

  handleCapture = () => {
    this.handleScreenshotClicked();
  }


  render() {
    const { screenCapture, takingScreenshot } = this.state;
    return (
      <ScreenCapture onEndCapture={this.handleScreenCapture} renderFixedView={this.handleStartScreenScapture}>
        {({ onStartCapture }) => (
          <>
            <main>
              <CanvasView
                onStartCapture={onStartCapture}
                takingScreenshot={takingScreenshot}
                noCamera={this.props.noCamera ? true : false}
                version={this.props.version}
                topic={this.props.topic}
                onShowCamera={this.props.onShowCamera}
                topicInfo={this.props.topicInfo}
                editRights={this.props.editRights}
                {...this.props.topicInfo} />
            </main>
            <Popup open={this.state.open} modal closeOnDocumentClick={false}>
              <div className="Popup modal">
                <div className="modal__body">
                  <div>
                    <input
                      type="text"
                      onChange={this.handleOnChange}
                      name="title"
                      value={this.state.title}
                    />
                  </div>
                  <div className="image__container">
                    {screenCapture && (
                      <img src={screenCapture} alt="screen capture" />
                    )}
                  </div>
                </div>
                <div className="modal__footer">
                  <div className='action-container'>
                    <div className='btn secondary' onClick={this.closeModal}>Cancel</div>
                    <a className='btn primary' download={`${this.state.title.toLowerCase().replace(/ /g, '-')}.jpg`} href={this.state.screenCapture}>Download</a>
                  </div>
                </div>
              </div>
            </Popup>
          </>
        )}
      </ScreenCapture>
    );
  }
}

export default ScrencaptureWrapper
