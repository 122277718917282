import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/firestore'; 
import 'firebase/auth';
import 'firebase/database'
import firebaseConfig from "../../lib/firebaseConfig";
import * as clipboard from "clipboard-polyfill";
import FileUploader from "react-firebase-file-uploader";
import AboutButton from '../../components/AboutButton/AboutButton'
import { gradientArray } from '../../data/gradients';
import { randomInt } from '../../lib/helperFunctions'

import './AddTopicView.scss'
import MediaLinks from '../../components/MediaLinks/MediaLinks';

var gradient = require('gradient-parser');

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const AddTopicView = (props) => {
    const { register, handleSubmit, errors, watch } = useForm();
    const topicWatch = watch("topic");
    const topicsRef = firebase.database().ref(`/topics/`);
    const [topicExists, setTopicExists] = useState(false);
    const [timerOn, setTimerOn] = useState(false);
    const [validBackground, setValidBackground] = useState(true);
    const [background, setBackground] = useState('#FFF');
    const [activeBackground, setActiveBackground] = useState(gradientArray[randomInt(0, gradientArray.length)]);
    const [progressUpload, setProgressUpload] = useState(0);
    let history = useHistory();

    const getRandomBackground = () => {
        return gradientArray[randomInt(0, gradientArray.length)]
    }

    const handleSeeNewBackground = () => {
        const newBg = getRandomBackground()
        console.log('new bg!')
        setActiveBackground(newBg)
    }

    const handleChangeBackground = (e) => {
        setActiveBackground(e.target.value)
        console.log('something---', e.target.value)
    }


    const onSubmit = (data, e) => {
        const lowerCaseTopic = data.topic.toLowerCase().trim().replace(/\s/g, "-");
        if (!validBackground) return;
        topicsRef.child(lowerCaseTopic).once("value", snapshot => {
            if (snapshot.exists()) {
                setTopicExists(true);
            } else {
                setTopicExists(false);
                storeEntryDatabase(lowerCaseTopic, data.time, data.resetable, background, data.private);
                // Add link to clipboard, if failed don't show notification on next screen
                clipboard.writeText("https://strange.garden/of/" + lowerCaseTopic).then(
                    () => {
                        history.push('/');
                        history.push({
                            pathname: '/of/' + lowerCaseTopic,
                            state: { new: true }
                        })
                    },
                    () => {
                        history.push('/');
                        history.push('/of/' + lowerCaseTopic)
                    }
                );
                e.target.reset();
            }
        });
    }

    useEffect(() => {
        if (topicExists) setTopicExists(false);
    }, [topicWatch, topicExists])

    useEffect(() => {
        const trimmedBackground = activeBackground.replace(";", "");
        setBackground(trimmedBackground);
        setValidBackground(false);
        if (CSS) {
            // Check if background CSS is supported and valid
            if (CSS.supports('background', trimmedBackground)) setValidBackground(true);
        } else {
            // Fallback for older browsers, will then only support gradients or hex code backgrounds
            try {
                gradient.parse(trimmedBackground);
                setValidBackground(true);
            } catch {
                // Check for hex codes
                if (/^#([0-9A-F]{3}){1,2}$/i.test(trimmedBackground)) setValidBackground(true);
            }
        }

    }, [activeBackground])


    const storeEntryDatabase = (topic, timer, resetable, background, privateGarden, sonicGarden) => {
        let time = timer * 60; //Minutes to seconds
        if (!timerOn) time = 0;
        const newItem = {
            time,
            resetable,
            endTime: '',
            background,
            private: privateGarden,
            sonic: false, //sonicGarden normally
            tempo: 140, // default tempo stored for sonic gardens
            createdAt: Date.now(),
            lastActiveAt: '',
            imagesNr: 0,
            thumbnailURL: '',
            thumbnailURLCreatedAt: Date.now(),

        }
        topicsRef.child(topic).set(newItem)
    }


    const handleSeeEveryGarden = () => {
        history.push('/everygarden')
    }

    const handleSeeStrangeGardenMobile = () => {
        history.push('/about')
    }

    const handleSetUrl = (url) => {
        const url_background = `no-repeat center/contain url("${url}")`
        setActiveBackground(url_background)
    }

    const handleUploadSuccess = (filename) => {
        firebase
            .storage()
            .ref("backgrounds")
            .child(filename)
            .getDownloadURL()
            .then(url => handleSetUrl(url));
        setProgressUpload(0);
    }

    const handleProgress = (progress) => {
        setProgressUpload(progress)
    }

    const renderUploader = () => {
        return (
            <label className='bg-button'>
                Upload
                <FileUploader
                    hidden
                    accept="image/*"
                    storageRef={firebase.storage().ref('backgrounds')}
                    // onUploadStart={this.handleUploadStart}
                    // onUploadError={this.handleUploadError}
                    onUploadSuccess={handleUploadSuccess}
                    onProgress={handleProgress}
                />

            </label>
        )
    }

    const renderProgressUploader = () => {
        return (
            progressUpload > 0 &&
            <div className='progress'>
                Uploading - {progressUpload} %
            </div>
        )
    }

    const renderAddTopicContent = () => {
        return (
            <>
                <div className='see-everygarden btn primary' onClick={() => handleSeeEveryGarden()}>
                    See all public Strange Gardens
                </div>
                <div className="outer-form">
                    <h1>Create a Garden</h1>
                    {props.new && <h4>Garden of {props.new} doesn't exist, create it?</h4>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label>
                            <p>Topic</p>
                            <input name="topic" defaultValue={props.new ? props.new : ''} placeholder='Favourite tools, red things...' type="text" ref={register({ required: true })} />
                            {errors.topic && <span className="short">This field is required</span>}
                            {topicExists && <span className="short">This topic exists</span>}
                        </label>

                        <label className="background">
                            <p>Background {<a href="https://cssgradient.io/" target="_blank" rel="noopener noreferrer">Create one</a>}</p>


                            <input className='active' type='text' ref={register()} onChange={(e) => handleChangeBackground(e)} value={activeBackground} />
                            {!validBackground && <span>Invalid <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/background" target="_blank" rel="noopener noreferrer">CSS background</a></span>}

                        </label>
                        <div className='bg-actions'>
                            {renderUploader()}
                            <div className='bg-button' onClick={() => handleSeeNewBackground()}>Random</div>
                        </div>
                        {renderProgressUploader()}
                        <label className="private">
                            <p>Private garden</p>
                            <input name="private" type="checkbox" ref={register()} />
                        </label>
                        <label className='private'>
                            <p>Timer {timerOn && '(in minutes)'}</p>
                            <input name="time-on" type="checkbox" ref={register()} onClick={() => setTimerOn(!timerOn)} />
                            <input className={timerOn ? 'active' : 'hidden'} name="time" defaultValue="15" type="number" ref={register({ min: 1, max: 1440 })} />
                            {errors.multipleErrorInput ?.type === "max" && <span>Time exceeds a day</span>}
                            {errors.multipleErrorInput ?.type === "min" && <span>Can't be less than 1 minute</span>}
                        </label>
                        <label className={timerOn ? 'resetable active' : 'resetable hidden'}>
                            <p>Submissions reset time</p>
                            <input name="resetable" type="checkbox" ref={register()} />
                        </label>
                        <input type="submit" value="Create" />
                    </form>

                </div>
                <div className='see-about-mobile ' onClick={() => handleSeeStrangeGardenMobile()}>About Strange Garden</div>
            </>
        )
    }

    return (
        <div className={`topicForm`} style={{ background: background }}>
            <AboutButton />
            {renderAddTopicContent()}
            <MediaLinks />
        </div>

    );
}

export default AddTopicView;
