import React, { useState, useEffect } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { useParams, useLocation } from 'react-router-dom'
import sample from 'lodash.sample'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database'
import 'react-html5-camera-photo/build/css/index.css';
import { useHistory } from 'react-router-dom';
import firebaseConfig from "../../lib/firebaseConfig"
import QRCode from 'qrcode.react'
import { ReactComponent as FlipCamera } from '../../assets/flipCamera.svg'
// import { removeBackgroundFromImageBase64 } from "remove.bg";
import { setGlobal } from 'reactn';
import axios from 'axios';

import './CameraView.scss'

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
const storage = firebase.storage()

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CameraView = (props) => {
    const baseCameraURL = 'https://strange.garden/camera/'
    const history = useHistory();
    let { type } = useParams();
    const [showNotice, setShowNotice] = useState(false)
    const [noticeText, setNoticeText] = useState('We are processing your image, and will be added to the canvas shortly.')
    const [showCamera, setShowCamera] = useState(true)
    const [version, setVersion] = useState('heaven')
    const imagesRef = firebase.database().ref(`/images/${version.toLowerCase()}/`)
    const [activeURL, setActiveURL] = useState(baseCameraURL)
    const gardenOfDelights = ['heaven', 'hell', 'reality']
    const [cameraView, setCameraView] = useState(true)

    const [topicInfo, setTopicInfo] = useState('');
    const topicsRef = firebase.database().ref(`/topics/`);
    const [ended, setEnded] = useState(false);

    const isSonicGarden = JSON.parse(useQuery().get('sonic'))
    const notes = ['Eb4', 'F4', 'G4', 'Ab4', 'Bb4', 'C5', 'D5', 'Eb5'];



    useEffect(() => {
        let ver = type ? type : window.location.pathname.replace('/', '');

        const retrieveTopicInfo = () => {
            topicsRef.child(type.toLowerCase()).on("value", snapshot => {
                if (snapshot.exists()) {
                    const obj = {
                        time: snapshot.val().time,
                        resetable: snapshot.val().resetable,
                        endTime: snapshot.val().endTime,
                        ended: snapshot.val().ended,
                        imagesNr: snapshot.val().imagesNr,
                        thumbnailURL: snapshot.val().thumbnailURL,
                        lastActiveAt: snapshot.val().lastActiveAt,
                        thumbnailURLCreatedAt: snapshot.val().thumbnailURLCreatedAt
                    }
                    setTopicInfo(obj)
                } else {
                    setTopicInfo(false)
                }
            });
        }

        setVersion(ver)
        setActiveURL(baseCameraURL + '/' + ver)
        retrieveTopicInfo()
    }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (topicInfo.time) {
            const isInDate = () => {
                if (topicInfo.endTime) {
                    const now = Date.now();
                    const endTime = new Date(topicInfo.endTime);
                    if (now <= endTime) {
                        // Set a clock for the end
                        console.log('ending in', endTime - now, 'ms');
                        setTimeout(() => {
                            setEnded(true);
                        }, endTime - now);
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }

            // Show camera if its in date, otherwise show notice that garden hasn't started yet or timer is done
            if (isInDate()) {
                setShowCamera(1);
                setShowNotice(false);
            } else {
                setShowCamera(0);
                setShowNotice(true);
                (topicInfo.time !== 0 && !topicInfo.endTime) ? setNoticeText("Garden isn't open yet") : setNoticeText("Garden is not alive anymore");
            }
        }
    }, [topicInfo, ended])

    // const removeBackground_api = (base64img) => {
    //     removeBackgroundFromImageBase64({
    //         base64img,
    //         apiKey: 'WWRvTHVf4Vj9QP8nkKYi4Gdg',
    //         size: "preview",
    //         type: "auto",
    //         crop: true
    //       }).then((result) => {
    //         const base64img = result.base64img;
    //         handleFirebaseBase64Upload(base64img)
    //         return base64img
    //       }).catch((errors) => {
    //        console.log(JSON.stringify(errors));
    //       });
    // }

    const converterDataURItoBlob = (dataURI) => {
        let byteString;
        let mimeString;
        let ia;

        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = encodeURI(dataURI.split(',')[1]);
        }
        // separate out the mime component
        mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    const removeBackground2 = (base64img) => {
        var imageBlob = converterDataURItoBlob(base64img);
        var bodyFormData = new FormData();
        bodyFormData.set('data', imageBlob);

        axios({
            method: 'post',
            url: 'https://u2net-image-yrzkm5zj6a-nw.a.run.app/',
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer'
        })
            .then(response => {
                const base64img = Buffer.from(response.data, 'binary').toString('base64')
                console.log('got img', base64img)
                handleFirebaseBase64Upload(base64img)
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }

    const handleTakePhoto = (dataUri) => {
        // const base64Img = dataUri.split(',')[1]
        // removeBackground_api(base64Img)

        // use our own api on CPU instead
        removeBackground2(dataUri)

        setNoticeText("We are processing your image, and will be added to the canvas shortly.");
        setShowCamera(0)
        setShowNotice(true)
        setTimeout(() => {
            setShowNotice(false)
            setShowCamera(1)
        }, 2500)

        handleUpdateThumbnail()
    }


    const handleUpdateThumbnail = () => {
        // here set it to take screenshot
        setGlobal({
            ...global,
            takeScreenshotThumbnail: true
        })
        console.log('update thumb')
    }


    const storeEntryDatabase = (location, posX, posY) => {
        const newItem = {
            location,
            posX,
            posY,

        }
        if (isSonicGarden) {
            newItem.pitch = sample(notes)
        }

        imagesRef.push(newItem);

        const newImagesNr = topicInfo.imagesNr + 1;



        topicsRef.child(type.toLowerCase()).update({
            imagesNr: newImagesNr,
            lastActiveAt: Date.now()
        });

        // Store resetted time for resetable timed gardens.
        if (topicInfo.endTime && topicInfo.resetable === true) {
            const date = new Date();
            const endTime = new Date(date.getTime() + 1000 * topicInfo.time);
            topicsRef.child(type.toLowerCase()).update({
                'endTime': endTime,
                imagesNr: topicInfo.imagesNr >= 0 ? topicInfo.imagesNr : 0 + 1
            });
        }
    }

    const handleFirebaseBase64Upload = (base64Img) => {
        let posX = Math.random() * .8
        let posY = Math.random() * .75
        const name = Date.now() + '.png'
        const uploadTask = storage.ref(`/images/${version.toLowerCase()}/${name}`).putString(base64Img, 'base64', { contentType: 'image/png' });
        uploadTask.on('state_changed',
            (snapShot) => {
                console.log(snapShot)
            }, (err) => {
                console.log(err)
            }, () => {
                storage.ref(`images/${version.toLowerCase()}`).child(name).getDownloadURL()
                    .then(fireBaseUrl => {
                        storeEntryDatabase(fireBaseUrl, posX, posY)
                    })
            })
    }

    const handleChangeVersion = (version) => {
        setVersion(version)
        setActiveURL(baseCameraURL + version)
    }

    const handleClose = () => {
        const isGardenOfDelights = gardenOfDelights.indexOf(version) !== -1;
        const goTo = isGardenOfDelights ? `/ofdelights` : `/of/${version}`
        history.push(`${goTo}`)
    }

    const renderQr = () => {
        return (
            <div className='qr-code'>
                <QRCode value={activeURL} />
                <div className='title'>
                    Scan this QR with your phone camera to take pictures with your device.
                </div>

            </div>
        )
    }

    const renderDelightsOption = () => {
        return (
            <div className='versions'>
                <div className={`version-type ${version === 'heaven' && 'active'}`} onClick={() => handleChangeVersion('heaven')}>
                    Heaven
                    </div>
                <div className={`version-type ${version === 'reality' && 'active'}`} onClick={() => handleChangeVersion('reality')}>
                    Reality
                    </div>
                <div className={`version-type ${version === 'hell' && 'active'}`} onClick={() => handleChangeVersion('hell')}>
                    Hell
                    </div>
            </div>
        )
    }

    const renderGarden = () => {
        return (
            <div className='versions'>
                <div className={`version-type active long`}>
                    Garden of {version}
                </div>

            </div>
        )
    }

    const isGardenOfDelights = gardenOfDelights.indexOf(version) !== -1;

    const handleFlipCamera = () => {
        setCameraView(!cameraView)
    }


    return (
        <div className={`CameraView ${props.fromDelightsGarden && props.version}`}>
            <div className='flip-camera' onClick={() => handleFlipCamera()}>
                <FlipCamera />
            </div>
            <div className='version-selector'>
                <div className='version-selector-desktop'>
                    <div className='title'>
                        Taking image for
                </div>
                    {isGardenOfDelights ? renderDelightsOption() : renderGarden()}


                </div>
                {renderQr()}
            </div>
            <div className={`camera-content ${showCamera ? 'show' : 'hide'} `} >
                <Camera
                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                    idealFacingMode={cameraView ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER}
                    isImageMirror={cameraView ? false : true}
                // idealResolution = {{width: 720, height: 720}}
                />
                {/* {imageAsUrl.imgUrl.length > 0 &&
                    <div className='img-preview'>
                        <img src={imageAsUrl.imgUrl} alt="" />
                    </div>} */}
            </div>
            {showNotice && <div className='image-taken'>
                <div className='content'>
                    {noticeText}
                </div>
            </div>}
            <div className='close-button' onClick={() => handleClose()}>
                X
            </div>
            <div className='version-selector'>
                <div className='version-selector-desktop'>
                    <div className='title'>
                        Taking image for
                </div>
                    {isGardenOfDelights ? renderDelightsOption() : renderGarden()}
                </div>
                {renderQr()}
            </div>
            <div className='close-button' onClick={() => handleClose()}>
                X
            </div>
        </div>

    );
}

export default CameraView;