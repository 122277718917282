import React from 'react';


import './FooterAbout.scss'

const FooterAbout = () => {
    
    return (
        <div className='FooterAbout'>
            <div className='follow-us'>Follow us in <a href={'https://www.instagram.com/strangestgarden/'}>Instagram</a> and <a href={'https://twitter.com/strangestgarden'}>Twitter</a></div>
            <div className='made-by'>Made by Luuk Rombouts and Marta Soto</div>
            
        </div>
    )
}

export default FooterAbout