import { Component } from "react";
import html2canvas from "html2canvas";

export default class ScreenCapture extends Component {
    handleHtml2CanvasCapture = () => {
        const body = document.querySelector("body");
        html2canvas(body, {
            allowTaint: true,
            useCORS: true,
        }).then(canvas => {
            let croppedCanvas = document.createElement("canvas");
            let croppedCanvasContext = croppedCanvas.getContext("2d");
            let dpr = window.devicePixelRatio || 1;

            const windowWidth = dpr * window.innerWidth;
            const windowHeight = dpr * window.innerHeight;

            croppedCanvas.width = windowWidth;
            croppedCanvas.height = windowHeight;

            croppedCanvasContext.drawImage(
                canvas,
                0,
                0,
                windowWidth,
                windowHeight,
                0,
                0,
                windowWidth,
                windowHeight
            );

            var ctx = canvas.getContext('2d');


            const resize_width = 700;
            const resize_height = windowHeight/windowWidth * resize_width;
            canvas.width = resize_width
            canvas.height = resize_height;
            ctx.drawImage(croppedCanvas, 0, 0, resize_width, resize_height);


            this.props.onEndCapture(canvas.toDataURL());
        })
    }

    handleClickTakeScreenShot = () => {
        this.props.renderFixedView();
        setTimeout(() => this.handleHtml2CanvasCapture(), 200)
    };

    renderChild = () => {
        const { children } = this.props;

        const props = {
            onStartCapture: this.handleClickTakeScreenShot
        };

        if (typeof children === "function") return children(props);
        return children;
    };

    render() {
        return this.renderChild();
    }
}
