export const gradientArray = [
    "linear-gradient(to right, #40e0d0, #ff8c00, #ff0080)",
    "linear-gradient(to right, #8360c3, #2ebf91)",
    `center center url("https://media4.giphy.com/media/l2Sqf1fbj1qrmaJ6E/giphy.gif?cid=ecf05e47fwswl7t033ra75cgv61li51049kn7kcpir92pccj&rid=giphy.gif")`,
    `center center url("https://media1.giphy.com/media/3og0IL6LDkIqFRNB9m/giphy.gif")`,
    `center center url("https://media3.giphy.com/media/3o6QL1OGg8MTnXychO/200w.webp?cid=ecf05e4769da3c1581c99c6ecc56c3cc8a53eab9b80000bc&rid=200w.webp")`,
    `center center url("https://media3.giphy.com/media/l0HemanxOLKjGw036/200.webp?cid=ecf05e47eaea17f4b4972258bc7ab8a8ed4e7a3fa76803fc&rid=200.webp")`,
    `center center url("https://media2.giphy.com/media/xUPGcnQoAGhWuLSF3y/giphy.webp?cid=ecf05e47e7f0eb06b7fb21df151eaed11128c15a5b7c17d7&rid=giphy.webp")`,
    `center url("https://i.pinimg.com/564x/32/0d/03/320d037328f81cecbd1e44d4e8cebc8b.jpg")`,
    "linear-gradient(180deg, #2af598 0%, #009efd 100%)",
    "linear-gradient(-225deg, #69EACB 0%, #EACCF8 48%, #6654F1 100%);",
    "radial-gradient( circle 759px at -6.7% 50%,  rgba(80,131,73,1) 0%, rgba(140,209,131,1) 26.2%, rgba(178,231,170,1) 50.6%, rgba(144,213,135,1) 74.1%, rgba(75,118,69,1) 100.3% );",
    "radial-gradient( circle 963px at 100.2% 3%,  rgba(12,85,141,1) 1.1%, rgba(195,201,200,1) 36.4%, rgba(250,225,190,1) 50.9%, rgba(255,206,187,1) 60.7%, rgba(249,140,69,1) 72.5%, rgba(255,255,255,1) 72.6% );",
    `no-repeat top left url("https://media0.giphy.com/media/j6prLTXu5q4fSK3oNN/giphy.gif?cid=ecf05e47a667b1006da8b05130f4191daca263486ffbbcba&rid=giphy.gif)`,
    "linear-gradient(128deg, rgba(237,237,237,1) 3%, rgba(237,237,237,1) 34%, rgba(126,75,231,1) 34%, rgba(191,75,231,1) 41%, rgba(231,75,224,0.5060399159663865) 71%);",
    `url("https://media4.giphy.com/media/4QlEbCS1Jb50k/200w.webp?cid=ecf05e477062883130eded8e6150dc1fd8602553bded0c0f&rid=200w.webp)`,
    `url("https://media2.giphy.com/media/ejE92iFFQDgsJdUwsg/giphy.gif?cid=ecf05e47eb7cf55e78140773f228bfa047270bb748d25e89&rid=giphy.gif)`,
    "linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%)",
    "linear-gradient(to left, #CD377D, #98FCC9);",
    `center url("https://i.pinimg.com/474x/98/54/98/9854985df9ca954f374cb6559254672a.jpg")`,
    `center url("https://i.pinimg.com/474x/33/32/26/33322683b2477bc082fdda348e9e7a84.jpg")`,
    `center url("https://i.pinimg.com/474x/f6/c5/2c/f6c52c849fab5a20f72058a472223699.jpg")`,
    "radial-gradient(circle 4000px at 23.7% 70%, rgba(55,105,38,1) 3%, rgba(32,101,8,1) 10%, rgba(233,230,218,1) 1%, rgba(233,230,218,1) 15%, rgba(233,230,218,1) 71%)",
    "radial-gradient(circle 6000px at 73.7% 70%, rgba(246,102,172,1) 3%, rgba(255,101,176,1) 10%, rgba(234,232,230,1) 1%, rgba(234,232,230,1) 15%, rgba(234,232,230,1) 71%);",
    "linear-gradient(121deg, rgba(245,255,43,1) 43%, rgba(245,255,43,1) 10%, rgba(234,232,230,1) 11%, rgba(234,232,230,1) 15%, rgba(234,232,230,1) 71%);",
    "linear-gradient(118deg, rgba(237,237,237,1) 3%, rgba(237,237,237,1) 34%, rgba(231,103,75,1) 34%, rgba(231,103,75,1) 41%, rgba(231,103,75,1) 71%);",
    "radial-gradient(circle 3000px at 16.7% 20%, rgba(215,159,171,1) 0%, rgba(170,1,37,1) 11%, rgba(178,255,217,0.5767663043478262) 1%, rgba(209,255,232,1) 15%, rgba(209,255,232,1) 71%);",
    `linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Ope`,
    "linear-gradient(to right, #2bc0e4, #eaecc6);"
]