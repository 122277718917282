import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import EveryGardenView from './pages/EveryGardenView/EveryGardenView'
import TopicView from './pages/TopicView/TopicView'
import AddTopicView from './pages/AddTopicView/AddTopicView'
import { setGlobal } from 'reactn';
import './App.scss';
import CameraView from './pages/CameraView/CameraView';
import ReactGA from 'react-ga';
import NavBar from './components/NavBar/NavBar';
import BuyMeACoffe from './components/BuyMeACoffee/BuyMeACoffee';
import AboutView from './pages/AboutView/AboutView';

function initializeReactGA() {
  ReactGA.initialize('UA-172877795-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

setGlobal({
  takeScreenshotThumbnail: false,
  everyGardenSortBy: 'newest'
});

initializeReactGA()
const App = () => {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <AddTopicView />
          </Route>
          <Route path="/add">
            <AddTopicView />
          </Route>
          <Route exact path="/everygarden">
            <EveryGardenView />
          </Route>
          <Route exact path="/camera/:type">
            <CameraView />
          </Route>
          <Route exact path="/about">
            <AboutView />
          </Route>
          <Route path="/of/:topicId/:act?">
            <TopicView />
          </Route>
          <Route exact path="/of/:topicId/adminViewForLuukAndMarta">
            <TopicView />
          </Route>
        </Switch>
      </Router>
      <BuyMeACoffe/>
      {/* <NavBar /> */}
    </div>
  );
}

export default App;
