import React from "react";
import { useHistory } from "react-router-dom";
import './AboutButton.scss'


const AboutButton = () => {
    const history = useHistory();

    return (
        <div className='AboutButton'>
            <div className='button-about' onClick={() => history.push('/about')}>
                About Strange Garden
            </div>
        </div>
    )
}

export default AboutButton;
