import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore'; 
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database'
import 'react-html5-camera-photo/build/css/index.css';
import firebaseConfig from "../../lib/firebaseConfig"
import { useHistory } from 'react-router-dom';
import { Camera as CameraIcon, X as CloseIcon } from 'react-feather';
import { useParams } from 'react-router-dom';
import ContentEditable from 'react-contenteditable'
import Draggable from 'react-draggable';
import QRCode from 'qrcode.react';
import { useGlobal, setGlobal } from 'reactn';
import he from 'he';

import './CanvasView.scss'
import AboutButton from '../../components/AboutButton/AboutButton'
import DeleteItemFromGarden from '../../components/DeleteItemFromGarden/DeleteItemFromGarden';
import DeleteGarden from '../../components/DeleteGarden/DeleteGarden';

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();

const CanvasView = (props) => {
    const [takeScreenshotThumbnail] = useGlobal('takeScreenshotThumbnail');
    const history = useHistory();
    let versionTopic = window.location.pathname.replace('/', '')

    let { topicId } = useParams();
    if (props.version === 'topic') {
        versionTopic = topicId
    }
    if (props.version === 'everygarden') {
        versionTopic = props.topic
    }

    const imagesRef = firebase.database().ref(`/images/${versionTopic.toLowerCase()}/`);
    const orderedImagesRef = firebase.database().ref(`/images/${versionTopic.toLowerCase()}/`).orderByChild('lastChange');
    const textsRef = firebase.database().ref(`/texts/${versionTopic.toLowerCase()}/`)
    const [imgArray, setImgArray] = useState([])
    const [textArray, setTextArray] = useState([])
    const dummy = useRef('');
    const dummyText = useRef('');
    const [dummyTextPos, setDummyTextPos] = useState([-100, -100])
    const [isDragging, setIsDragging] = useState(false)
    const [QROpen, setQROpen] = useState(false)


    const [size, setSize] = React.useState({
        h: window.innerHeight,
        w: window.innerWidth
    })

    useEffect(() => {
        // if the thumbnail hasn't changed in the last 10 minutes, take new screenshot and upload it
        if (!!props.topicInfo.thumbnailURLCreatedAt) {
            if ((Date.now() - props.topicInfo.thumbnailURLCreatedAt) * .001 > 600) {
                setGlobal({
                    ...global,
                    takeScreenshotThumbnail: true
                })
            }
        } else {
            setGlobal({
                ...global,
                takeScreenshotThumbnail: true
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (takeScreenshotThumbnail) {
            props.onStartCapture()
        }
    }, [takeScreenshotThumbnail, props.onStartCapture]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        orderedImagesRef.on('value', (snapshot) => {
            if (!snapshot.val()) return

            // Important to keep the order from the snapshot, so not saving in its own object.
            let array = []
            snapshot.forEach(child => {
                let item = child.val()
                item.id = child.key
                array.push(item)
            })

            if (props.items) array = array.slice(0, props.items);
            setImgArray(array)

        });

        textsRef.on('value', (snapshot) => {
            const newVal = snapshot.val()
            if (newVal) {
                let array = Object.keys(newVal).map(i => {
                    newVal[i]['id'] = i;
                    return newVal[i]
                })
                if (props.items) array = array.slice(0, props.items);
                setTextArray(array)
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Resize them screens
    useEffect(() => {
        const handleResize = debounce(() => {
            setSize({
                h: window.innerHeight,
                w: window.innerWidth
            })
        }, 500)

        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const handleTextDrag = (e, ui) => {
        if (!isDragging) setIsDragging(true);
        textsRef.child(ui.node.id).update({
            'posX': ui.x / size.w,
            'posY': ui.y / size.h
        });
    }

    const handleImgDrag = (e, ui) => {
        if (!isDragging) setIsDragging(true);
        imagesRef.child(ui.node.id).update({
            'posX': ui.x / size.w, //ui.x, 
            'posY': ui.y / size.h, //ui.y
            'lastChange': Date.now()
        });
    }

    const handleImgClick = (id, e) => {
        if (isDragging) return;

        let curScale;

        imagesRef.child(id).once('value').then((snapshot) => {
            const newVal = snapshot.val();

            curScale = newVal.scale ? newVal.scale : 0.5;
            if (curScale === 0.5) curScale = 1;
            else if (curScale === 1) curScale = 0.25;
            else if (curScale === 0.25) curScale = 0.5;

            imagesRef.child(id).update({
                'scale': curScale
            });
        });
    }

    const handleCanvasClick = (e) => {
        if (e.target !== e.currentTarget || isDragging) return;

        dummy.current.focus();
        setDummyTextPos([e.pageX, e.pageY]);
    }

    const handleTextInput = (e) => {
        dummyText.current = e.target.value;
    }

    const handleKeyDown = (e) => {
        // ESC Key
        if (e.which === 27) {
            removeDummy();
        }
        // Enter key
        if (e.which === 13) {
            e.preventDefault();
            if (dummyText.current) storeTextEntryDatabase(dummyText.current.toString(), dummyTextPos[0] / size.w, dummyTextPos[1] / size.h);
            removeDummy();
        }
    }

    const removeDummy = () => {
        setDummyTextPos([-100, -100]);
        dummyText.current = '';
    }

    const storeTextEntryDatabase = (htmltext, posX, posY) => {
        const text = he.decode(htmltext.replace(/(<([^>]+)>)/ig, ""));
        const newItem = {
            text,
            posX,
            posY
        }
        textsRef.push(newItem)
    }

    const renderDraggableCanvas = () => {
        return (
            <div>
                <div className='title'>
                    {versionTopic.length > 0 ? versionTopic.replace(/-/g, ' ') : 'Contemporary garden of delights'}
                </div>
                <div onClick={handleCanvasClick} className='canvasItems'>
                    {!!imgArray && imgArray.length > 0 && imgArray.map(item => {
                        return props.takingScreenshot ? renderFixedImage(item) : renderDraggableImage(item)
                    })}
                    {!!textArray && textArray.length > 0 && textArray.map(item => {
                        return renderDraggableText(item)
                    })}
                </div>
            </div>
        )
    }


    const handleDeleteGarden = () => {
        if (versionTopic.length > 0) {
            const topicRef = firebase.database().ref(`/topics/${versionTopic.toLowerCase()}/`);
            topicRef.remove();
        }

        !!imgArray && imgArray.length > 0 && imgArray.map(item => {
            handleDeleteItem(item)
            return null;
        })

        !!textArray && textArray.length > 0 && textArray.map(item => {
            handleDeleteText(item)
            return null;
        })

    }

    const handleDeleteItem = (item) => {
        console.log('deleting', item)
        //delete image from realtime database
        const image = imagesRef.child(item.id)
        image.remove();

        //delete image from storage
        const itemNameArray = item.location.split('%2');
        const itemName_ = itemNameArray.filter(str => str.includes('.png'))
        const itemName_2 = itemName_.length > 0 && itemName_[0].split('.png')
        const itemName = `${itemName_2[0].substring(1, itemName_2[0].length)}.png`
        const imageStorageRef = storage.ref(`/images/${versionTopic.toLowerCase()}/${itemName}`)

        imageStorageRef.delete().then(() => {
            console.log('deleted----')
        }).catch((error) => {
            console.log('error deleting from storage----', error)
        });
    }

    const handleDeleteText = (item) => {
        const text = textsRef.child(item.id)
        text.remove();
    }

    const renderDraggableImage = (item) => {

        return (
            <Draggable
                position={{ x: Math.floor(size.w * item.posX), y: Math.floor(size.h * item.posY) }}
                key={item.id}
                onStop={() => {
                    setTimeout(() => setIsDragging(false), 300)
                }}
                onDrag={handleImgDrag}>
                <div id={item.id} style={{ position: 'relative' }} >
                    {props.editRights && <DeleteItemFromGarden handleDelete={handleDeleteItem} item={item} />}
                    <img
                        onClick={(e) => handleImgClick(item.id, e)}
                        src={item.location}
                        style={{
                            transform: `scale(${item.scale ? item.scale : '0.5'}) translate(-50%, -50%)`,
                            zIndex: item.zIndex ? item.zIndex : 100
                        }}
                        draggable="false"
                        alt={'img'}
                        className={`img-entry`}
                        crossOrigin="true" />
                </div>
            </Draggable>
        )
    }

    const renderFixedImage = (item) => {

        return (
            <div
                style={{ position: 'absolute', transition: `all .3s easi=e-in`, zIndex: '20', width: `100%`, left: Math.floor(size.w * item.posX), top: Math.floor(size.h * item.posY) }} key={item.id} onStop={() => setTimeout(() => setIsDragging(false), 300)} onDrag={handleImgDrag}>
                <img
                    src={item.location}
                    style={{ transform: `scale(${item.scale ? item.scale : '.75'}) translate(-50%, -50%)` }}
                    draggable="false"
                    alt={'img'}
                    className={`img-entry`}
                    crossOrigin="true" />
            </div>
        )
    }

    const renderText = (text) => {
        return <p className='text text-entry'>{text}</p>
    }

    const renderLink = (text) => {
        const textArray = text.split(' ');
        return (
            <div className='text-link-block'>
                {textArray.map(word => {
                    return word.includes('https://') ?
                        <a className='link' href={word}>{`${word.includes('strange.garden') ? `🌱 ${word} 🌱` : word} `}</a>
                        : <span className='text-entry'>{`${word} `}</span>
                })}
            </div>
        )
    }


    const renderDraggableText = (item) => {
        const isLink = item.text.includes('https://');
        return (
            <Draggable
                position={{ x: Math.floor(size.w * item.posX), y: Math.floor(size.h * item.posY) }}
                key={item.id}
                onStop={() => {
                    setTimeout(() => setIsDragging(false), 300)
                }}
                onDrag={handleTextDrag}>
                <div id={item.id}>
                    {props.editRights && <DeleteItemFromGarden handleDelete={handleDeleteText} item={item} />}
                    {isLink ? renderLink(item.text) : renderText(item.text)}
                </div>
            </Draggable>
        )
    }

    const handleCamera = () => {
        let path = `/camera/${versionTopic}`;
        history.push(path)
    }

    const isInDate = () => {
        if (props.endTime) {
            const now = Date.now();
            const endTime = new Date(props.endTime);
            if (now <= endTime) return true;
            else return false;
        } else {
            return true;
        }
    }
    const handleSeeStrangeGardenMobile = () => {
        history.push('/about')
    }


    return (
        <div style={props.background && { background: props.background }} className={`CanvasView ${versionTopic}`}>

            {props.editRights && <DeleteGarden handleDeleteGarden={handleDeleteGarden} />}
            {renderDraggableCanvas()}
            <ContentEditable onKeyDown={handleKeyDown} onBlur={removeDummy} innerRef={dummy} tagName='p' onChange={handleTextInput} style={{ left: `${dummyTextPos[0]}px`, top: `${dummyTextPos[1]}px` }} data-ph="Start typing.." html={dummyText.current} className='text dummy' />
            {<div className={`about-btn ${props.takingScreenshot ? 'about-hidden' : 'about-show'}`}><AboutButton NewButton /></div>}
            {<div className='see-about-mobile ' onClick={() => handleSeeStrangeGardenMobile()}>About</div>}
            {!props.noCamera && (props.time === 0 || isInDate() || props.fromDelightsGarden) &&
                <div className={`${!imgArray.length || QROpen ? "qr-icon first" : "qr-icon"} ${props.takingScreenshot ? 'hidden' : 'show'}`} onClick={() => setQROpen(!QROpen)}>
                    <CloseIcon className="icon close" color="black" size={12} />
                    <QRCode renderAs="svg" className="qrCode" value={`https://strange.garden/camera/${versionTopic}`} />
                    <p className="qrCode qr-text">Scan with phone <br /> to open camera</p>
                </div>
            }
            {!props.noCamera && (props.time === 0 || isInDate() || props.fromDelightsGarden) &&
                <div className={`camera-icon ${props.takingScreenshot ? 'hidden' : 'show'}`} onClick={() => handleCamera()}>
                    <CameraIcon className="icon" color="black" size={28} />
                </div>
            }
        </div>

    );

    function debounce(fn, ms) {
        let timer
        return _ => {
            clearTimeout(timer)
            timer = setTimeout(_ => {
                timer = null
                fn.apply(this, arguments)
            }, ms)
        };
    }
}

export default CanvasView;
