import React from 'react';
import { Instagram, Twitter } from 'react-feather';
import './MediaLinks.scss'

const MediaLinks = () => {
    return (
        <div className='MediaLinks'>
            <a className='icon-media' href={'https://www.instagram.com/strangestgarden/'}>
                <Instagram color="black" />
            </a>
            <a className='icon-media' href={'https://twitter.com/strangestgarden'}>
                <Twitter color="black"  />
            </a>

        </div>
    )
}

export default MediaLinks;
